// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---wolfy-subpages-hire-mdx": () => import("../wolfy-subpages/hire.mdx" /* webpackChunkName: "component---wolfy-subpages-hire-mdx" */),
  "component---wolfy-subpages-mentorerie-mdx": () => import("../wolfy-subpages/mentorerie.mdx" /* webpackChunkName: "component---wolfy-subpages-mentorerie-mdx" */),
  "component---wolfy-subpages-nwpatech-mdx": () => import("../wolfy-subpages/nwpatech.mdx" /* webpackChunkName: "component---wolfy-subpages-nwpatech-mdx" */),
  "component---wolfy-subpages-resume-mdx": () => import("../wolfy-subpages/resume.mdx" /* webpackChunkName: "component---wolfy-subpages-resume-mdx" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

