import { Col, Row, Container, Button } from "react-bootstrap";
import Gallery from 'react-grid-gallery';
import React from 'react';
export default {
  Col,
  Row,
  Container,
  Button,
  Gallery,
  React
};